<template>
  <div class="stationManagement">
    <div v-show="!isSearch && !isSearchPOI">
      <div class="mapContainer" ref="mapContainer"></div>
      <div
        class="mapHeader"
        :class="{ exitPickup: pickupInfo.open }"
        :style="{ top: appBarHeight }"
      >
        <div class="backBtn" @click="backToPrev">
          <img
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back_icon.png"
            alt=""
          />
        </div>
        <template v-if="!pickupInfo.open">
          <div class="searchBar" @click="isSearch = true">
            <span v-if="parkInfo && parkInfo.parkCode" class="filled">{{
              parkInfo.parkName
            }}</span>
            <span v-else>请选择网格</span>
            <img
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/arrow-down.png"
              alt=""
            />
          </div>
          <div class="rightBtns" :style="{ top: rightBtnHeight }">
            <div class="search" @click="isSearchPOI = true">
              <img
                src="../assets/imgs/StationManagement/search@2x.png"
                alt=""
              />
              <span>搜索</span>
            </div>
            <div class="collect" @click="collectClick">
              <img src="../assets/imgs/StationManagement/collect.png" alt="" />
              <span>采集</span>
            </div>
          </div>
        </template>
      </div>
      <!-- <div class="toLocalEnv" @click="toLocalEnviroment">勿点</div> -->
      <!-- 定位按钮 -->
      <!-- v-if="!pickupInfo.open" -->
      <img
        @click="getLocation"
        class="getLocation"
        :class="{
          pickuping: pickupInfo.open,
          parking: !!parkingInfo,
          hasLabel: parkingInfo?.poiType,
        }"
        src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/location.png"
        alt=""
      />
      <!-- 采集中，中心点 -->
      <div v-if="pickupInfo.open" class="centerMarker">
        <template v-if="highPrecisionPhase === 1">
          <span>拖动地图确定朝向</span>
          <img
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/station_avaliable.png"
            alt=""
          />
        </template>
        <template v-else>
          <span v-if="pickupInfo.enabled">此处可采集POI</span>
          <span v-else>请拖动至网格区域内采集POI</span>
          <img
            v-if="pickupInfo.enabled"
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/station_avaliable.png"
            alt=""
          />
          <img
            v-else
            alt=""
            src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/staion_disabled.png"
          />
        </template>
      </div>
      <!-- 采集中，采集信息 -->
      <div v-if="pickupInfo.open" class="pickupContent">
        <div v-if="collectPOIType === 1" class="warning">
          请拖动地图，将大头针保持在站点所在位置
        </div>
        <div v-else class="warning">
          <span v-if="highPrecisionPhase === 0">
            请拖动地图，将大头针保持在高精地图车道线上
          </span>
          <span v-else-if="highPrecisionPhase === 1">
            请拖动地图确定行驶方向，无人车将按朝向行驶</span
          >
        </div>
        <div class="parkName">
          <span>网格</span>
          <span>{{ parkInfo.parkName }}</span>
        </div>
        <div class="stationName">
          <span>站点名称</span>
          <span class="nameInner">
            <input
              maxlength="30"
              type="text"
              placeholder="请输入站点名称"
              v-model="pickupInfo.stationName"
            />
            <img
              v-if="pickupInfo.stationName"
              @click="pickupInfo.stationName = null"
              src="../assets/imgs/input_clear.png"
              alt=""
            />
          </span>
        </div>
        <div class="stationType">
          <span>站点类型</span>
          <span class="typeInner">
            <span @click="showTypePicker = true">{{ selectedType.text }}</span>
            <img
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/arrow-right.png"
              alt=""
            />
          </span>
        </div>
        <div
          v-if="collectPOIType === 1"
          class="pickupButton"
          @click="handlePickupClick"
          :class="{ disabled: !pickupInfo.enabled || !pickupInfo.stationName }"
        >
          确定采集
        </div>
        <template v-else>
          <div
            v-if="highPrecisionPhase === 0"
            class="pickupButton"
            @click="handlePickupHighPrClick"
            :class="{
              disabled: !pickupInfo.enabled || !pickupInfo.stationName,
            }"
          >
            确定POI位置
          </div>
          <div v-else class="pickupButton-phase2">
            <div @click="highPrecisionPrevPhase">上一步</div>
            <div
              :class="{ disabled: highPrecisionDiabled }"
              @click="highPrecisionPickup"
            >
              确定采集
            </div>
          </div>
        </template>
      </div>
      <!-- 停车区指引 -->
      <div
        v-if="parkingInfo"
        class="parkingGuide"
        :class="{ hasLabel: parkingInfo.poiType }"
      >
        <!-- <div v-if="parkingInfo.poiType" class="poiType">
          {{ POIType[parkingInfo.poiType].name }}
        </div> -->
        <div class="parkingContent" :class="{ hasLabel: parkingInfo.poiType }">
          <div class="footer-btn" @click="goToParkingGuide">查看停车区指引</div>
          <div class="footer-btn gotoContacts" @click="gotoContacts">
            管理联系人
          </div>
          <div class="footer-delete" @click="deleteStation">
            <div>
              <img
                src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/Frontend/zsd/delete.png"
                alt=""
              />
            </div>
            <div>删除</div>
          </div>
        </div>
      </div>
      <Tool
        :class="{
          'tool-pickuping': pickupInfo.open,
          'tool-parking': parkingInfo,
          'tool-hasLabel': parkingInfo?.poiType,
        }"
      ></Tool>
    </div>
    <div v-if="isSearch">
      <SearchPanel
        :appBarHeight="appBarHeight"
        @onCancel="handleSearchCancel"
        @onSelect="handleSearchSelect"
      >
      </SearchPanel>
    </div>
    <div v-if="isSearchPOI">
      <SearchPOI
        :appBarHeight="appBarHeight"
        :parkInfo="parkInfo"
        :coordinate="lngLat"
        @onCancel="handleCancelSearchPOI"
        @onSelect="handleSearchPOISelect"
      ></SearchPOI>
    </div>
    <van-popup v-model="showTypePicker" round position="bottom">
      <van-picker
        swipe-duration="1000"
        show-toolbar
        title="站点类型"
        :columns="stationTypeColumns"
        @cancel="showTypePicker = false"
        @confirm="onTypeConfirm"
      />
    </van-popup>
    <Dialog
      v-if="dialog.visible"
      @handleOk="handleDialogOk"
      @handleCancel="handleDialogCancel"
      :dialogContent="dialog.content"
    ></Dialog>
    <HighPrecision :map="$options.map"></HighPrecision>
    <CollectPopup
      :isShow.sync="isShowCollectPopup"
      @onCollectGeneralPOI="pickUpStation"
      @onCollectHighPrecisionPOI="handleCollectHighPrecisionPOI"
    ></CollectPopup>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import SearchPanel from '@/components/SearchPanel.vue';
import Tool from '@/views/StationManagement/Tool.vue';
import HighPrecision from '@/views/StationManagement/HighPrecision.vue';
import CollectPopup from '@/views/StationManagement/CollectPopup.vue';
import SearchPOI from '@/views/StationManagement/SearchPOI.vue';
import { findGridPointInfo, getStationListByCoordinate } from '@/api/api';
import { searchNearestStationList, coordinateTransform } from '@/api/apiv2';
import { deletePoi, createPoi } from '@/api/apiv2';
import Dialog from '@/components/Dialog.vue';
import NDialog from '@/components/NDialog'; // 支持promise的dialog
import {
  requestLocationPermission,
  getLocation,
  getAngle,
  getYaw,
  isIOS,
} from '@/utils/index';
import { mapGetters } from 'vuex';
import { POIType } from '@/constant';
// import Transform from "@/utils/transform";

// const transform = new Transform();

const userLocation =
  'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/userLocation.png';

const inputRule = /^[\u4E00-\u9FA5a-zA-Z0-9-]{0,30}$/;

const CONFIG = { zoom: 10, zooms: [2, 25], center: [116.405994, 39.913828] };
const POLYGONSTYLE = {
  strokeColor: '#FC7A3A',
  strokeOpacity: 0.6,
  fillColor: '#FC7A3A',
  fillOpacity: 0.1,
  strokeWeight: 3,
};

export default {
  components: {
    SearchPanel,
    Dialog,
    Tool,
    HighPrecision,
    CollectPopup,
    SearchPOI,
  },
  prevMarker: null, // 上次点击的marker
  map: null,
  markers: null,
  data() {
    // makers 站点列表， map 地图实例， AMap 地图类， geolocation 定位， polygon 电子围栏
    return {
      // appBarHeight: "100px",
      appBarHeight: `${
        (sessionStorage.getItem('_appBarHeight') ?? 0) / 100 + 0.1
      }rem`,
      rightBtnHeight: `1rem`,
      submitClickable: true, // 采集按钮是否可点，节流
      isSearch: false,
      isSearchPOI: false,
      parkInfo: null,
      // parkInfo: { parkName: '北京恒通商务园' },
      pickupInfo: {
        open: false,
        enabled: false, // 该点是否可采集
        stationName: '',
        coordinate: null,
      },
      parkingInfo: null, // 停车区指引
      dialog: {
        visible: false,
        content: '',
      },
      showTypePicker: false,
      selectedType: { text: '接驳点', value: 2 },
      stationTypeColumns: [
        { text: '接驳点', value: 2 },
        { text: '接管点', value: 4 },
        { text: '风险点', value: 3 },
        { text: '网点', value: 1 },
        { text: '停车点', value: 6 },
        { text: '装车点', value: 5 },
        { text: '发车点', value: 7 },
        { text: '卸车点', value: 8 },
      ],
      isShowCollectPopup: false,
      collectPOIType: -1, // 点击网格搜索列表回调,记录当前采集状态: -1不采集, 1采集普通 2采集高精
      lngLat: [116.405994, 39.913828], // 定位时，当前页面中心点位置
      POIType,
      highPrecisionPhase: 0, // 采集高精站点阶段
      highPrecisionStart: null,
      highPrecisionEnd: null,
      highPrecisionDiabled: true, // 确定采集是否disabled
      mapZoom: CONFIG.zoom,
    };
  },
  computed: {
    ...mapGetters({
      isShowLabel: 'getisShowLabel',
    }),
    largeView() {
      return this.mapZoom > 14;
    },
  },
  watch: {
    isShowLabel(show) {
      const prevData = this.$options.prevMarker?.getExtData();
      if (show) {
        this.labelsLayer.getAllOverlays().forEach((marker) => {
          const extData = marker.getExtData();
          marker.setText({
            content: extData.name,
            direction: 'top',
            style: { backgroundColor: '#ffffff' },
            offset: [0, -4],
          });
        });
      } else {
        this.labelsLayer.getAllOverlays().forEach((marker) => {
          const extData = marker.getExtData();
          if (prevData?.id !== extData.id) marker.setText({ content: null });
        });
      }
    },
    largeView(value) {
      this.labelsLayer.setCollision(!value);
    },
  },
  methods: {
    initMap() {
      this.$loadingCircle.start();
      AMapLoader.load({
        key: 'e618efe9bc3dbbbd1ddfa9d847f49d74',
        version: '2.0',
        plugins: ['AMap.Geolocation', 'AMap.MapboxVectorTileLayer'],
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.$options.map = new AMap.Map(this.$refs.mapContainer, CONFIG);
          window.sendLocationInfo = (lat, lng) => {
            if (lat && lng) {
              this.drawUserMarker('complete', { position: [lng, lat] });
              if (!this.parkInfo?.parkName) this.getPOINearbyCenter([lng, lat]);
            } else {
              this.drawUserMarker('error');
              if (!this.parkInfo?.parkName) this.getPOINearbyCenter();
            }
            // this.parkInfo = null;
          };
          getLocation(); // 调用webview

          // this.getPOINearbyCenter();

          this.labelsLayer = new this.AMap.LabelsLayer({
            collision: true,
            opacity: 1,
            zIndex: 1000,
          });

          this.$options.map.add(this.labelsLayer);

          this.userMarker = new this.AMap.Marker({
            position: [0, 0],
            visible: false,
            offset: [-14, -14],
            zIndex: 9,
            icon: new AMap.Icon({
              size: [28, 28],
              image: userLocation,
              imageSize: [28, 28],
            }),
          });

          // 高精采集基点
          this.highPrecisionBaseMarker = new this.AMap.Marker({
            position: [0, 0],
            visible: false,
            offset: [-5, -5],
            zIndex: 101,
            icon: new AMap.Icon({
              size: [10, 10],
              image: './assets/images/high_base.png',
              imageSize: [10, 10],
            }),
          });

          this.$options.map.add(this.highPrecisionBaseMarker);

          // 方向箭头
          this.directionArrowMarker = new this.AMap.Marker({
            position: [0, 0],
            visible: false,
            offset: [-4, -6],
            zIndex: 100,
            icon: new AMap.Icon({
              size: [8, 12],
              image: './assets/images/direction_arrow.png',
              imageSize: [8, 12],
            }),
          });

          this.$options.map.add(this.directionArrowMarker);

          // 方向线
          this.directionLine = new this.AMap.Polyline({
            path: [[0, 0]],
            zIndex: 100,
            strokeColor: '#EF2F41',
            strokeWeight: 2,
            strokeStyle: 'dashed',
            strokeOpacity: 1,
            strokeDasharray: [10, 2],
          });

          this.$options.map.add(this.directionLine);

          this.$options.map.on('complete', () => {
            this.$loadingCircle.end();
          });

          // 点击地图，如果没有marker，把选中marker取消选中
          this.$options.map.on('click', () => {
            this.cancelMarkerHighlight();
          });

          this.$options.map.add(this.userMarker);

          this.$options.map.on('zoomchange', (event) => {
            this.mapZoom = event.target.getZoom();
          });
        })
        .catch((error) => console.log(error));
    },
    drawUserMarker(state, result) {
      if (state === 'complete') {
        this.userMarker.setPosition(result.position);
        this.userMarker.show();
        window.currentLocation = result.position;
        if (this.parkInfo?.parkName) {
          // 有parkName 不调整层级
          this.$options.map.setCenter(result.position, true);
        } else {
          this.$options.map.setZoomAndCenter(17.64, result.position, true);
        }
      } else {
        this.userMarker.hide();
        requestLocationPermission();
      }
    },
    toLocalEnviroment() {
      window.location.href = 'http://172.16.20.142:8080/stationManagement';
    },
    backToPrev() {
      if (this.pickupInfo.open) {
        this.pickUpEnd();
      } else {
        window.location.href = 'neolix://more';
      }
    },
    // 取消POI搜索
    handleCancelSearchPOI() {
      this.isSearchPOI = false;
    },
    // POI搜索回调
    handleSearchPOISelect(item) {
      this.isSearchPOI = false;
      this.parkingInfo = item;
      this.$options.map.setCenter([item.lng, item.lat], true);
      const marker = this.labelsLayer
        .getAllOverlays()
        .find((d) => d.getExtData().id === item.id);
      if (marker) this.markerClick({ target: marker });
    },
    collectClick() {
      if (isIOS()) {
        this.pickUpStation();
      } else {
        this.isShowCollectPopup = true;
      }
    },
    // 普通POI采集
    pickUpStation() {
      this.collectPOIType = 1;
      this.parkingInfo = null;
      this.isShowCollectPopup = false;
      if (this.parkInfo?.parkCode) {
        this.pickupInfo.open = true;
        this.$options.map.on('moveend', this.pickUpStart);
        this.pickUpStart();
      } else {
        this.isSearch = true;
        this.$toast('请先选择一个网格');
      }
    },
    // 高精POI采集
    handleCollectHighPrecisionPOI() {
      this.collectPOIType = 2;
      this.parkingInfo = null;
      this.isShowCollectPopup = false;
      if (this.parkInfo?.parkCode) {
        this.pickupInfo.open = true;
        this.$store.commit('updateisShowHighPrecision', true);
        this.$options.map.on('moveend', this.pickUpStart);
        this.pickUpStart();
        // this.$toast("采集高精POI");
      } else {
        this.isSearch = true;
        this.$toast('请先选择一个网格');
      }
    },
    /**
     * 取消marker 高亮
     * @param {*} showText 是否继续显示marker 文本
     */
    cancelMarkerHighlight() {
      this.parkingInfo = null;
      if (this.$options.prevMarker) {
        const prevData = this.$options.prevMarker.getExtData();
        this.$options.prevMarker.setIcon({
          anchor: 'center',
          type: 'image',
          image: `./assets/images/POI/${prevData.poiType ?? 0}.png`,
          size: [22, 22],
        });
        if (!this.isShowLabel) {
          this.$options.prevMarker.setText({ content: null });
        }
        this.$options.prevMarker.setRank(prevData.id);
        this.$options.prevMarker.setzIndex(prevData.id);
        this.$options.prevMarker = null;
      }
    },
    pickUpStart(prop) {
      // 如果地图移动过， 取消disabled
      if (prop && this.highPrecisionPhase === 1) {
        this.highPrecisionDiabled = false;
      }
      const center = this.$options.map.getCenter();
      this.pickupInfo.coordinate = center;

      this.pickupInfo.enabled = true;

      // if (this.polygon) {
      //   this.pickupInfo.enabled = this.isPointInRing(
      //     center,
      //     this.polygon.getPath()
      //   );
      // } else {
      //   this.pickupInfo.enabled = true;
      // }
      if (this.highPrecisionPhase === 1) {
        const end = this.$options.map.getCenter();
        const start = this.highPrecisionBaseMarker.getPosition();
        this.highPrecisionStart = start;
        this.highPrecisionEnd = end;
        const angle = getAngle(start, end);
        this.directionArrowMarker.setPosition(end);
        this.directionArrowMarker.setAngle(angle);
        this.directionArrowMarker.show();
        this.directionLine.setPath([start, end]);
        this.directionLine.show();
      }
    },
    pickUpEnd() {
      this.selectedType = { text: '接驳点', value: 2 };
      this.pickupInfo.open = false;
      this.pickupInfo.enabled = false;
      this.pickupInfo.stationName = '';
      this.highPrecisionDiabled = true;
      this.collectPOIType = -1;
      this.highPrecisionDiabled = true;
      this.highPrecisionPrevPhase();
      this.$options.map.off('moveend', this.pickUpStart);
    },
    // 确定POI
    handlePickupHighPrClick() {
      if (!this.pickupInfo.enabled || !this.pickupInfo.stationName) return;
      const mapCenter = this.$options.map.getCenter();
      // todo 确定当前点，画方向
      this.highPrecisionBaseMarker.setPosition(mapCenter);
      this.highPrecisionBaseMarker.show();
      this.highPrecisionPhase = 1;
    },
    // 高精采集过程中，点击上一步
    highPrecisionPrevPhase() {
      this.highPrecisionPhase = 0;
      this.highPrecisionDiabled = true;
      this.directionArrowMarker.hide();
      this.directionLine.hide();
      this.highPrecisionBaseMarker.hide();
    },

    // 高精采集完成
    async highPrecisionPickup() {
      if (this.highPrecisionDiabled) return;

      const startArr = [
        this.highPrecisionStart.lng,
        this.highPrecisionStart.lat,
      ];
      const endArr = [this.highPrecisionEnd.lng, this.highPrecisionEnd.lat];
      // const zone = parseInt(this.highPrecisionStart.lng / 6 + 31);
      // const highCoordinate = transform.lngLatToUtm(
      //   [this.highPrecisionStart],
      //   zone
      // );

      let highCoordinate;

      const coordRes = await coordinateTransform({
        x: startArr[0],
        y: startArr[1],
      });
      if (coordRes?.data?.utm) {
        highCoordinate = coordRes.data.utm;
      } else {
        this.$toast('未获取到zone值，POI必须在高精地图内');
        return;
      }

      const heading = getYaw([startArr, endArr]);

      const yaw = [Math.cos(heading * 0.5), 0, 0, -Math.sin(heading * 0.5)];

      this.$loadingCircle.start();
      const { lng, lat } = this.highPrecisionStart;
      const checkNearby = await getStationListByCoordinate({ lat, lng });
      let message;
      if (checkNearby.stations.length) {
        message = `所选位置附近${checkNearby.distance}m内存在站点，是否确定采集？`;
      } else {
        message = '确定采集站点吗？';
      }
      const result = await NDialog.confirm({ message });

      if (result !== 'confirm') return;

      createPoi({
        parkCode: this.parkInfo.parkCode,
        parkName: this.parkInfo.parkName,
        name: this.pickupInfo.stationName,
        poiType: this.selectedType.value,
        highPrecision: 1,
        lat: highCoordinate[1], // 后端接口反了，所以反着来
        lng: highCoordinate[0],
        originalCoordinates: `${lng},${lat}`,
        qx: yaw[1],
        qy: yaw[2],
        qz: yaw[3],
        qw: yaw[0],
        showType: 1,
      })
        .then(() => {
          this.getStationList();
          this.$toast('站点采集成功');
          this.pickupInfo.stationName = '';
          this.pickupInfo.open = false;
          this.highPrecisionPhase = 0;
          this.directionArrowMarker.hide();
          this.directionLine.hide();
          this.highPrecisionBaseMarker.hide();
          this.pickUpEnd();
          this.submitClickable = true;
        })
        .catch(() => {})
        .finally(() => {
          this.$loadingCircle.end();
          // this.pickUpEnd();
        });
    },
    // 调用webview方法
    getLocation() {
      getLocation();
    },

    // 根据中心点获取POI列表
    async getPOINearbyCenter(coordinate) {
      const lngLat = coordinate || [
        this.$options.map.getCenter().lng,
        this.$options.map.getCenter().lat,
      ];
      this.lngLat = lngLat;
      // coordinate 存在定位成功，不存在获取当前中心点
      const list = await searchNearestStationList({
        lng: lngLat[0],
        lat: lngLat[1],
      });
      this.drawMarkers(list);
    },

    // 取消输入
    handleSearchCancel() {
      this.isSearch = false;
    },
    // 输入选中
    handleSearchSelect(item) {
      this.parkInfo = item;
      this.isSearch = false;
      this.getParkGridInfo(item.parkCode);

      if (this.collectPOIType === 1) {
        this.pickUpStation();
      } else if (this.collectPOIType === 2) {
        this.handleCollectHighPrecisionPOI();
      }
      // this.collectPOIType = -1;
    },
    /**
     * 根据parkcode查询网格信息
     * @param {String} parkCode
     */
    getParkGridInfo(parkCode) {
      // 清空默认polygon
      if (this.polygon) {
        this.$options.map.remove(this.polygon);
        this.polygon = null;
      }

      findGridPointInfo({ parkCode }).then((res) => {
        if (res?.gridList) {
          this.polygon = new this.AMap.Polygon({
            zIndex: 1,
            path: res.gridList,
            ...POLYGONSTYLE,
          });
          this.polygon.on('click', this.cancelMarkerHighlight);
          this.$options.map.add(this.polygon);
          this.$options.map.setBounds(
            this.polygon.getBounds(),
            true,
            [20, 20, 20, 20]
          );
        } else if (res?.coordinate) {
          this.$options.map.setCenter(res.coordinate.split(','), true);
        }
      });
      this.getStationList();
    },

    getStationList() {
      searchNearestStationList({
        parkCode: this.parkInfo.parkCode,
      }).then((res) => {
        if (res) {
          this.drawMarkers(res);
        }
      });
    },

    drawMarkers(list) {
      this.labelsLayer?.clear();
      this.$options.prevMarker = null;
      this.parkingInfo = null;

      this.$options.markers = list.map((station) => {
        const marker = new this.AMap.LabelMarker({
          icon: {
            anchor: 'center',
            type: 'image',
            image: `./assets/images/POI/${station.poiType ?? 0}.png`,
            size: [22, 22],
          },
          rank: station.id,
          // offset: [-11, -22],
          extData: station,
          position: [station.lng, station.lat],
          zIndex: station.id,
          topWhenClick: true,
          text: this.isShowLabel
            ? {
                content: station.name,
                direction: 'top',
                style: { backgroundColor: '#ffffff' },
                offset: [0, -4],
              }
            : { content: null },
        });
        marker.on('click', this.markerClick);
        return marker;
      });
      // this.$options.map.add(this.$options.markers);
      this.labelsLayer.add(this.$options.markers);
    },

    markerClick(e) {
      const currentData = e.target.getExtData();
      if (!this.pickupInfo.open) {
        this.parkingInfo = currentData;
      }
      if (this.$options.prevMarker) {
        const prevData = this.$options.prevMarker.getExtData();
        if (prevData.id === currentData.id) return;
        if (!this.isShowLabel)
          this.$options.prevMarker.setText({ content: null });
        this.$options.prevMarker.setIcon({
          anchor: 'center',
          type: 'image',
          image: `./assets/images/POI/${prevData.poiType ?? 0}.png`,
          size: [22, 22],
        });
        this.$options.prevMarker.setRank(prevData.id);
        this.$options.prevMarker.setzIndex(prevData.id);
      }
      e.target.setRank(Infinity);
      e.target.setzIndex(Infinity);
      this.$options.prevMarker = e.target;
      e.target.setText({
        content: currentData.name,
        direction: 'top',
        style: { backgroundColor: '#ffffff' },
        offset: [0, -4],
      });
      e.target.setIcon({
        anchor: 'bottom-center',
        type: 'image',
        image: `./assets/images/POI/${currentData.poiType ?? 0}_selected.png`,
        size: [40, 40],
      });
    },
    // 坐标点是否在区域内
    isPointInRing(point, path) {
      return this.AMap.GeometryUtil.isPointInRing(point, path);
    },
    // 确定采集
    async handlePickupClick() {
      // disabled
      if (
        !this.pickupInfo.enabled ||
        !this.pickupInfo.stationName ||
        !this.submitClickable
      )
        return;
      if (!inputRule.test(this.pickupInfo.stationName)) {
        this.$toast('请不要使用特殊符号或表情');
        return;
      }
      this.submitClickable = false; // 按钮暂时不可点
      const { lng, lat } = this.pickupInfo.coordinate;
      const checkNearby = await getStationListByCoordinate({ lat, lng });
      if (checkNearby.stations.length) {
        this.dialog.content = `所选位置附近${checkNearby.distance}m内存在站点，是否确定采集？`;
      } else {
        this.dialog.content = '确定采集站点吗？';
      }

      this.dialog.visible = true;
    },
    async handleDialogOk() {
      this.$loadingCircle.start();
      const { lng, lat } = this.pickupInfo.coordinate;
      createPoi({
        parkCode: this.parkInfo.parkCode,
        parkName: this.parkInfo.parkName,
        name: this.pickupInfo.stationName,
        poiType: this.selectedType.value,
        highPrecision: 0,
        showType: 1,
        lat,
        lng,
      })
        .then(() => {
          this.dialog.visible = false;
          this.pickUpEnd();
          this.getStationList();
          this.$toast('站点采集成功');
        })
        .finally(() => {
          this.submitClickable = true;
          this.$loadingCircle.end();
        });
    },

    handleDialogCancel() {
      this.dialog.visible = false;
      this.dialog.content = '';
      this.submitClickable = true;
      // this.collectPOIType = -1;
    },

    // 跳转联系人
    gotoContacts() {
      window.location.href = `neolix://contacts?station=${this.parkingInfo.id},${this.parkingInfo.name}`;
    },

    // 删除站点
    async deleteStation() {
      const result = await NDialog.confirm({ message: '是否确定删除该站点？' });
      if (result === 'confirm') {
        const res = await deletePoi({
          id: this.parkingInfo.id,
        }).catch((error) => {
          console.log(error);
        });
        if (res) {
          this.$toast('站点已删除');
          this.labelsLayer.remove(this.$options.prevMarker);
          this.$options.prevMarker = null;
          this.parkingInfo = null;
        }
      }
    },
    goToParkingGuide() {
      // window.location.href =
      //   "http://172.16.20.142:8080/parkingGuide?stationName=CreatedByYZH01&stationId=81373";
      this.$router.push({
        name: 'parkingGuide',
        query: this.parkingInfo,
      });
    },
    onTypeConfirm(target) {
      this.selectedType = target;
      this.showTypePicker = false;
    },
  },
  mounted() {
    this.initMap();
  },
  activated() {
    if (this.$options.map) {
      this.$options.map.resize();
    }
  },
};
</script>

<style scoped lang="scss">
:deep(.van-picker) {
  .van-picker__cancel {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
  }

  .van-picker__title {
    font-size: 16px;
    font-weight: bold;
  }

  .van-picker__confirm {
    font-size: 14px;
    font-weight: 400;
    color: rgba(67, 110, 255, 1);
  }

  .van-picker-column__item {
    font-size: 16px;
    font-weight: 400;

    &.van-picker-column__item--selected {
      font-size: 18px;
      font-weight: 400;
    }
  }
}

:deep(.amap-marker-label) {
  padding: 0;
  line-height: initial;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.toLocalEnv {
  position: absolute;
  z-index: 999;
  right: 12px;
  top: 200px;
  font-size: 20px;
  width: 50px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: white;
  color: cyan;
  border-radius: 18px;
}

.stationManagement {
  position: relative;

  .centerMarker {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -100%);
    z-index: 9;

    span {
      font-size: 12px;
      color: #222222;
      font-weight: bold;
      display: block;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      padding: 4px 12px;
      margin-bottom: 4px;
      width: max-content;
    }

    img {
      width: 28px;
      height: 40px;
    }
  }

  .pickupContent {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 6rem;
    background: white;
    padding: 0.32rem 0.28rem;
    box-sizing: border-box;
    z-index: 10;
    box-shadow: 0px -0.04rem 0.12rem 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.32rem 0.32rem 0px 0px;

    .warning {
      background: #fef2ea;
      border-radius: 0.1rem;
      font-size: 0.26rem;
      color: #fe8000;
      padding: 0.17rem 0.2rem;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 0.24rem;
      font-weight: bold;
    }

    .parkName {
      display: flex;
      padding: 0.24rem 0;
      border-bottom: 1px solid rgba(234, 238, 238, 1);

      span {
        display: block;
        font-size: 0.32rem;

        &:nth-child(1) {
          min-width: 1.52rem;
          font-size: 0.32rem;
          color: #9798ab;
        }

        &:nth-child(2) {
          min-width: 1.52rem;
          color: #000000;
          font-weight: bold;
        }
      }
    }

    .stationName {
      padding: 0.24rem 0;
      border-bottom: 1px solid rgba(234, 238, 238, 1);
      display: flex;

      span {
        display: block;
        min-width: 1.52rem;
        font-size: 0.32rem;
        color: #9798ab;
      }

      .nameInner {
        flex-grow: 1;
        display: flex;
        align-items: center;
        input {
          flex-grow: 1;
          font-size: 0.32rem;
          color: #000000;
          font-weight: bold;

          &::placeholder {
            color: #bfc0cb;
            font-weight: normal;
          }
        }
        img {
          width: 18px;
          height: 18px;
          margin-right: 12px;
        }
      }
    }

    .stationType {
      padding: 0.24rem 0;
      border-bottom: 1px solid rgba(234, 238, 238, 1);
      display: flex;
      align-items: center;

      > span {
        min-width: 1.52rem;
        font-size: 0.32rem;
        color: #9798ab;
      }

      .typeInner {
        flex-grow: 1;
        display: flex;
        align-items: center;

        span {
          flex-grow: 1;
          font-weight: bold;
          color: rgba(0, 0, 0, 1);
        }

        img {
          width: 14px;
          height: 14px;
          margin-right: 12px;
        }
      }
    }

    .pickupButton {
      height: 0.92rem;
      line-height: 0.94rem;
      font-size: 0.28rem;
      color: #ffffff;
      font-weight: bold;
      border-radius: 0.46rem;
      background: #436eff;
      border-radius: 0.46rem;
      margin: 0.4rem 0;
      text-align: center;

      &.disabled {
        background: #cbd5fb;
      }
    }

    .pickupButton-phase2 {
      height: 0.92rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0.4rem 0;

      > div {
        width: 3.15rem;
        height: 0.92rem;
        opacity: 1;
        border-radius: 0.48rem;
        text-align: center;
        font-size: 0.28rem;
        font-weight: bold;
        line-height: 0.92rem;
        &:nth-child(1) {
          color: #436eff;
          font-weight: bold;
          background: rgba(255, 255, 255, 1);
          border: 0.04rem solid rgba(67, 110, 255, 1);
        }

        &:nth-child(2) {
          background: #436eff;
          color: white;
          line-height: 0.94rem;
          &.disabled {
            background: #cbd5fb;
          }
        }
      }
    }
  }

  .parkingGuide {
    font-size: initial;
    background: white;
    position: absolute;
    width: 100vw;
    bottom: 0;
    left: 0;
    border-radius: 16px 16px 0px 0px;
    padding: 0 24px;
    box-sizing: border-box;

    // &.hasLabel {
    //   height: 108px;
    // }

    .poiType {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      width: 80px;
      height: 24px;
      background: url(../assets/imgs/label_bg.png) center no-repeat;
      background-size: contain;
      text-align: center;
      line-height: 24px;
      color: rgba(67, 110, 255, 1);
      font-weight: bold;
    }

    .parkingContent {
      // &.hasLabel {
      //   margin-top: 18px;
      // }

      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .footer-btn {
        // width: 300px;
        // height: 46px;
        // opacity: 1;
        // flex-grow: 1;

        // margin-right: 32px;
        padding: 12px 19px;
        border-radius: 46px;
        background: rgba(67, 110, 255, 1);

        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0px;
        // line-height: 44px;
        color: rgba(255, 255, 255, 1);
        text-align: center;

        &.gotoContacts {
          padding: 10px 17px;
          background: white;
          color: rgba(70, 95, 253, 1);
          border: 2px solid rgba(67, 110, 255, 1);
        }
      }

      .footer-delete {
        > div {
          &:nth-child(1) {
            width: 26px;
            height: 26px;

            img {
              width: 26px;
              height: 26px;
            }
          }

          &:nth-child(2) {
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0px;
            // line-height: 33.6px;
            color: rgba(0, 0, 0, 1);
          }
        }
      }
    }
  }

  .mapContainer {
    width: 100vw;
    height: 100vh;

    ::v-deep .amap-copyright {
      display: none !important;
    }
  }

  .mapHeader {
    width: 100vw;
    position: absolute;
    // top: 0.1rem;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: flex-start;
    // justify-content: space-around;
    padding: 0 0.2rem;

    &.exitPickup {
      justify-content: flex-start;
      padding-left: 0.08rem;
      box-sizing: border-box;
    }

    .backBtn {
      background: #ffffff;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 0.2rem;
      box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.16);
      box-sizing: border-box;
      padding: 0.08rem;
      margin-right: 0.16rem;

      img {
        display: block;
        width: 0.64rem;
        height: 0.64rem;
      }
    }

    .searchBar {
      background: #ffffff;
      width: 6.14rem;
      height: 0.8rem;
      box-shadow: 0px 0px 0.1rem 0px rgba(0, 0, 0, 0.16);
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.3rem;
      color: #bfc0cb;
      box-sizing: border-box;
      padding: 0 0.24rem;

      span {
        &.filled {
          font-size: 0.3rem;
          color: #000000;
          font-weight: 500;
        }
      }

      img {
        width: 0.32rem;
        height: 0.32rem;
      }
    }

    .rightBtns {
      position: absolute;
      width: 0.8rem;
      // height: 1.52rem;
      right: 0.2rem;
      background: #ffffff;
      box-shadow: 0px 0px 0.1rem 0px rgba(0, 0, 0, 0.16);
      border-radius: 0.2rem;
      box-sizing: border-box;
      padding: 0.16rem 0;

      .search,
      .collect {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .search {
        padding-bottom: 0.14rem;

        &::after {
          content: '';
          position: absolute;
          width: 0.56rem;
          height: 1px;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          background: rgba(224, 224, 224, 1);
        }
      }

      .collect {
        padding-top: 0.14rem;
      }

      img {
        width: 0.36rem;
        height: 0.36rem;
        display: block;

        &:nth-child(1) {
          margin-bottom: 0.02rem;
        }
      }

      span {
        font-size: 0.2rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  .getLocation {
    position: absolute;
    bottom: 0.12rem;
    right: 0.12rem;
    width: 1rem;
    height: 1rem;

    &.pickuping {
      bottom: calc(0.12rem + 6rem);
    }

    &.parking {
      bottom: 100px;

      &.hasLabel {
        bottom: 98px;
      }
    }
  }

  .tool-pickuping {
    bottom: calc(0.3rem + 6rem);
  }

  .tool-parking {
    bottom: 100px;

    &.tool-hasLabel {
      bottom: 100px;
    }
  }
}
</style>
